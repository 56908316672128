<!-- 资产管理-部门资产-部门资产流水 归还明细-->
<template>
    <div class="return-flow-layout">
        <div v-if="!errorShow" class="right-panel">
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow" class="expand-filter-wrapper content-main">
                <template v-if="!showAddEdit">
                    <div class="filter-wrap">
                        <expand-filter :formData="formData" marginBottom="0px" :closeWidth="1020" @changeBtnFormType="changeBtnFormType" @changeSel="changeSel" @clickBtn="clickBtn">
                        </expand-filter>
                        <div class="button-line"></div>
                        <div style="padding-bottom: 10px;">
                            <el-button type="enquiry" class="primary" @click="exportAll">
                                导出
                            </el-button>
                        </div>
                    </div>
                    <!-- 表格 -->
                    <table-data
                        id="table"
                        ref="table"
                        :key="tableKey"
                        v-loading="loadingTable"
                        :config="table_config"
                        :tableData="table_data"
                    >
                        <!-- <template v-slot:operation="slotData">
                            <xk-button type="danger" size="mini" @click="details([{ id: slotData.data.id }])">详情</xk-button>
                        </template> -->
                    </table-data>
                    <!-- 分页组件 -->
                    <pagination
                        :total="total"
                        :page.sync="listQuery.pageNum"
                        :limit.sync="listQuery.pageRow"
                        @pagination="getList"
                    />
                </template>
                <!-- 归还弹窗 -->
                <dialog-wrapper :dialog-obj="dialogObjDetails" @handleClose="closeDialogDetails">
                    <DetailInfo />
                </dialog-wrapper>
            </div>
        </div>
        <error :errorShow="errorShow"></error>
    </div>
</template>

<script>
    import {
        // 业务组件
        DialogWrapper,
        Pagination,
        // 功能组件
        Error,
        Loading,
        debounce
    } from 'common-local';
    import {
        downloadFile,
    } from '@/libs/utils.js'

    import {
        mapState
    } from 'vuex';
    // 自定义颜色
    import btnSty from '@/mixins/btnSty.js'
    import TableData from "../../Sub/TableData"
    import OperationBtnView from '../AssetMaintenance/OperationBtnView.vue'
    import { CampusSafetyModel } from "@/models/CampusSafety"
    import {AssetMaintenanceModel}from "@/models/AssetMaintenance"
    import DetailInfo from './Sub/DetailInfo.vue';
    import ExpandFilter from "../../Sub/ExpandFilter.vue";
    import onResize from "@/mixins/onResize";

export default {
    mixins: [onResize],
    name: 'ReturnFlow',
    components: {
        TableData,
        DialogWrapper,
        // 功能组件
        Error,
        Loading,
        Pagination,
        OperationBtnView,
        DetailInfo,
        ExpandFilter
    },
    props:{
        assetFlow:{
            type:Object,
        }
    },
    data() {
        return {
            tableKey: 0,
            listQuery: {
                storeId: '',
                deptId:'',
                pageNum: 1,
                pageRow: 20,
                recordType:'4',
                groupName: '',
                accessType: '',
                categoryId:'',
                declarer:'',
                assetsName:'',
                startTime:'',
                endTime:'',
            },
            assetClassList: {
                schoolId: '',
                storeId: ''
            },
            total: 0,
            // 头部筛选
            formData: {
                data: [
                    {
                        type: 'datePick',
                        label: '',
                        value: '',
                        placeholder: '归还日期',
                        key: 'createTime'
                    },
                    {
                        type: 'select',
                        label: '',
                        value: '',
                        placeholder: '仓库名称',
                        key: 'storeId',
                        list:[]
                    },
                    {
                        type: 'select',
                        label: '',
                        value: '',
                        placeholder: '资产品类',
                        key: 'categoryId',
                        list: []
                    },
                    {
                        type: 'input',
                        label: '',
                        value: '',
                        placeholder: '资产名称',
                        key: 'assetsName'
                    },
                    {
                        type: 'input',
                        label: '',
                        value: '',
                        placeholder: '申报人',
                        key: 'declarer'
                    }
                ],
                btnList: [{
                    type: "primary",
                    text: '查询',
                    fn: 'primary',
                }, {
                    type: "enquiry",
                    text: '重置',
                    fn: 'reset'
                },
                ],
                btnFormType: true,
            },
            // 表格配置
            table_config: {
                check: false,
                thead: [
                    {
                        label: "购入时间",
                        prop: "buyTime",
                        labelWidth: '140px',
                        align: 'center',
                        type: "function",
                        callBack(row) {
                            return row.buyTime ? row.buyTime : '-'
                        },
                    },
                    {
                        label: "归还日期",
                        align:'center',
                        prop: "createTime",
                        type: "function",
                        callBack(row) {
                            return row.createTime ? row.createTime.slice(0, 16):'-'
                        },
                    }, {
                        label: "申报人",
                        prop: "declarer",
                        align:'center',
                    },
                    {
                        label: "所属仓库",
                        prop: "storeName",
                        align:'center',
                    },
                    {
                        label: "资产品类",
                        prop: "categoryName",
                        type:'popoverText',
                        align:'center',
                    },
                    {
                        label: "资产名称",
                        prop: "assetsName",
                        align:'center',
                    },
                    {
                        label: "归还数量",
                        prop: "operateNumber",
                        align: "center",
                    },
                    {
                        label: "位置",
                        prop: "assetLocation",
                        align: 'center',
                        type: "function",
                        callBack(row) {
                            return row.assetLocation ? row.assetLocation : '-'
                        },
                    },
                    {
                        label: "资产总额",
                        prop: "totalPrice",
                        labelDescription: "单位：元",
                        type: "function",
                        labelWidth: '120px',
                        align: "right",
                        callBack(row) {
                            if (row.totalPrice) {
                                return row.totalPrice.toLocaleString()
                            } else {
                                return 0
                            }
                        }
                    },{
                    label: "",
                    prop: "",
                    labelWidth:'26px',
                }
                    // {
                    //     label: "操作",
                    //     type: "slot",
                    //     labelWidth: "200",
                    //     slotName: 'operation'
                    // }
                ],
                height: '',
            },
            // 表格数据
            table_data: [],
            // 添加编辑显示
            showAddEdit: false,
            // 功能组件
            errorShow: false,
            loadingShow: false,
            loadingTable: true,
            // 新增
            ruleForm: {
                id: '',
                schoolId: '',
                groupName: '',// 出入组名称
                accessType: '1', // 出入类型  1出校 2入校 3不区分出入校
                accessRule: '1', // 出入规则  1每周 2自定义
                time: '',// 出入时间，辅助星期和日历选择，仅添加时展示
                ruleInfo: [], // 出入时间信息
                organInfo: [], // 选中机构信息
                personInfo: [], // 选中人员信息
                showName: '', // 人员输入框展示使用
                userPopulation: '1',
            },
            rules: {
                groupName: [{
                    required: true,
                    message: '请输入出入组名称',
                    trigger: ['change', 'blur']
                }],
                accessType: [{
                    required: true,
                    message: '请选择出入类型',
                    trigger: ['change', 'blur']
                }],
                accessRule: [{
                    required: true,
                    message: '请选择出入规则',
                    trigger: ['change', 'blur']
                }],
                showName: [{
                    required: true,
                    message: '请选择出入人员',
                    trigger: ['change', 'blur']
                }],
                userPopulation: [{
                    required: true,
                    message: '请选择使用人群',
                    trigger: ['change', 'blur']
                }],
            },
            // 表格选中
            tableListSel: [],

            /**
             * dialogObjDetails 详情弹窗
             * */
            dialogObjDetails: {
                title: '资产详情',
                dialogVisible: false,
                width: '600px',
                key: 1
            },
            show:false,
        }
    },
    computed: {
        ...mapState({
            schoolId: state => state.schoolId,
        })
    },
    async created() {
        this.ruleForm.schoolId = this.listQuery.schoolId = this.assetClassList.schoolId  = this.schoolId
        this.listQuery.deptId = this.assetFlow.deptId
        await this.getCategory()
        this.getList()
    },
    mounted() {

    },
    methods: {
        //仓库名称
        async getCategory(){
            const assetMaintenanceModel = new AssetMaintenanceModel();
            await assetMaintenanceModel.getWarehouseName({schoolId: this.schoolId}).then((res) => {
                if (res.data.code === '200') {
                    res.data.data.forEach((item) => {
                        let obj = {
                            label: item.storeName,
                            value: item.id
                        }
                        this.formData.data.forEach((item) => {
                            if (item.key === 'storeId') {
                                item.list.push(obj)
                            }
                        })

                    })
                }
            })

        },
        //资产品类
        changeSel (data) {
            if (data.key === 'storeId') {
                this.assetClassList.storeId = data.value;

                this.formData.data.forEach((item) => {
                    if (item.key === 'categoryId') {
                        item.list = [];
                        item.value = '';
                        this.listQuery.categoryId = '';
                    }
                })

                const assetMaintenanceModel = new AssetMaintenanceModel();
                assetMaintenanceModel.getAssetClassList(this.assetClassList).then((res) => {
                    if(res.data.code === '200') {
                        res.data.data.forEach((item) => {
                            let obj = {
                                label: item.categoryName,
                                value: item.id
                            }
                            this.formData.data.forEach((item) => {
                                if (item.key === 'categoryId') {
                                    item.list.push(obj)
                                }
                            })
                        })
                    } else {

                    }

                })

            }
        },
        getList(t) {
            let _this = this;
            this.loadingTable = true
            if (t === 1) {
                this.listQuery.pageNum = 1
            }
            let assetMaintenanceModel = new AssetMaintenanceModel();
            assetMaintenanceModel.departmentalAssetFlow(this.listQuery).then((res) => {
                this.show = true
                this.handleRes(res, () => {
                    console.log('归还明细 ',res.data.data.list);
                    this.table_data = res.data.data.list
                    this.total = res.data.data.totalCount
                    this.tableKey = this.tableKey + 1
                    this.loadingTable = false
                })
                _this.$nextTick(()=>{
                    _this.$refs.table.$refs.multipleTable.doLayout()
                })
            })
        },

        // 关闭弹窗组件
        closeDialog() {
            this.dialogObj.dialogVisible = false;
        },

        // 选中
        handleSelectionChange(data) {
            this.tableListSel = data;
        },
        // 导出
        exportAll() {
            var obj = {};
            obj = JSON.parse(JSON.stringify(this.listQuery))
            delete obj.pageNum
            delete obj.pageRow
            downloadFile({url: '/school/schoolDeptAssetsRecord/export', form: obj}, () => {
                this.$message.success('导出成功')
            }, () => {

            })
        },

        clickBtn(ev) {
            switch (ev.item.fn) {
                case 'primary': // 查询
                    this.formData.data.forEach((item) => {
                        if (item.key === 'createTime') {
                            if (item.value && item.value.length === 2) {
                                this.listQuery.startTime = item.value[0] + ' 00:00:00'
                                this.listQuery.endTime = item.value[1] + ' 23:59:59'
                            } else {
                                this.listQuery.startTime = ''
                                this.listQuery.endTime = ''
                            }
                        } else {
                            this.listQuery[item.key] = item.value;
                        }
                    })

                    this.getList(1);
                    break;
                case 'reset': // 重置
                this.formData.data.forEach((item) => {
                        item.value = '';
                        this.listQuery[item.key] = ''
                    })
                    this.listQuery.startTime = ''
                    this.listQuery.endTime = ''
                    this.getList(1);
                    break;
                default:
                    break;
            }
        },
        // 返回部门资产列表页
        clickBackBtn(){
            this.$eventDispatch('backList')
        },
        // 添加编辑返回
        back() {

        },

        /**
         * 表格按钮操作
         * details 详情
         * closeDialogDetails 关闭详情弹窗
         * */
        details (data) {
            this.dialogObjDetails.dialogVisible = true;
        },
        closeDialogDetails () {
            this.dialogObjDetails.dialogVisible = false;
        },
        // 接口请求结果处理
        handleRes(res, fn) {
            if (res.data.code === '200') {
                fn()
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg)
            }
        },
    }
}
</script>

<style scoped lang="scss">
.filter-wrap {
    display: flex;
    // justify-content: space-between;
    // text-align: center;
    align-items: flex-start;
    background-color: #fff;
    padding: 10px 20px 0;
    margin-bottom: 10px;
    border-radius: 4px;
    overflow: hidden;

    .expand-filter {
        padding: 0;
    }
}
</style>
